/* google font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap");

/* layout */
:root {
  --primary: #1a50ac;
  --error: #e7195a;
}
body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}
header {
  background: #fff;
}
header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #333;
  text-decoration: none;
}
.pages {
  
  padding: 0px;
  margin: 0 auto;
}

/* homepage */
.home {
  display: grid;
  /* grid-template-columns: 3fr 1fr; */
  gap: 50px;
}
.pilot-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px 20px;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}
.pilot-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.pilot-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.pilot-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

/* new pilot form */
label,
input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}

.expired-cell {
  text-align: center;
  border-top: none;
  border-left: none;
  font-size: 0.6rem !important;
  color: black;
  font-weight: 600;
  padding: 2px;
  text-decoration: none;
  
  font-family: Arial, sans-serif;
  border: 1px solid white;
  background:red;
 
}

.warning-cell {
  text-align: center;
  border-top: none;
  border-left: none;
  font-size: 0.6rem !important;
  color: black;
  font-weight: 400;
  padding: 2px;
  text-decoration: none;
  font-family: Arial, sans-serif;
  border: 1px solid white;
  background: rgba(128, 128, 128, 0.850);
  
}


.valid-cell {
  text-align: center;
  border-top: none;
  border-left: none;
  font-size: 0.6rem !important;
  padding: 2px;
  color: #92D050 !important;
  font-weight: 400;
  text-decoration: none;
  font-family: Arial, sans-serif;
  border: 1px solid white;
  background: #92D050;
  
}


.trigcell {
  text-align: center !important;
  border-top: none;
  border-left: none;
  padding: 2px;
  font-size: 0.6rem !important;
  color: black;
  font-weight: 700;
  text-decoration: none;
  font-family: Arial, sans-serif;
  border: 1px solid white;
  background: rgb(127, 127, 127);
  
}

.tableheader {
  height: 65pt;
  font-size: 10pt;
  border: 1px solid white;
  color: white;
  padding: 5px;
  text-align: center;
}


/* @background colors */
.bg-black {
  background-color: black;
}

.bg-white {
  background-color: white;
}

.bg-grey {
  background-color: grey;
}

.bg-red {
  background-color:red;
}





.cardcaps {
  padding: 50px 50px;
  width: 65%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  background-color: lightgrey;
  margin-bottom: 20px;
  border: none !important;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2)
}

.inline-flex {
  display: inline-flex;
  align-items: center;
}

input {
  margin: 8px 8px;
  width: 250px;
  background-color: rgba(211, 211, 211, 0.87);
  font-family: Arial, sans-serif;
  border: 0px;
  
}

.submit-form {
  margin: 30px 20px;
}

label {
  font-family: Arial, sans-serif;
      margin: 10px 5px;
      font-weight: bold;
      border-top: #000000 solid 1px;
  }

input:invalid {
  
  color: rgb(241, 50, 29);
  
}



table.minimalistBlack {
  border: 3px solid #000000;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table.no-top-border {
  border-top: none;
}
table.minimalistBlack td, table.minimalistBlack th {
  border: 1px solid #000000;
  padding: 5px 5px;
}
table.minimalistBlack tbody td {
  font-size: 16px;
}
table.minimalistBlack thead {
  background: #CFCFCF;
  background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
  background: -webkit-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
  background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
  border-bottom: 3px solid #000000;
}
table.minimalistBlack thead th {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: left;
}
table.minimalistBlack tfoot td {
  font-size: 5px;
}

